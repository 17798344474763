import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

function AboutMe() {
    return (
        <div className="scrollableSection" id="aboutMe">
            <div className="h1Container">
                <div className="h1ContainerOverlay">
                    <h1>
                        About Me
                    </h1>
                </div>
            </div>

            <div id="aboutMePhotoContainer">
            <img id="aboutMePhoto" src="/josh-BgxLMz4H.jpg" />
            </div>

            <div id="aboutMeIntro">
                <p>
                    My name is Josh. I am an experienced senior software developer with a proven track record of leading complex development
                    projects and mentoring team members. I have deep technical proficiency in software development
                    methodologies, coding languages, and system architecture. I am well-equipped to oversee and coordinate
                    diverse teams, drive strategic initiatives, and optimize software development processes for
                    organizational success.
                </p>
            </div>

            <div id="aboutMeBio">
                <h3>Find Me Online:</h3>
                <p>
                    <FontAwesomeIcon icon={faLinkedin} />&nbsp;&nbsp;
                    <a target="_blank" rel="nofollow noreferrer" href="https://www.linkedin.com/in/jlr2k8">https://www.linkedin.com/in/jlr2k8</a>
                </p>
                <p>
                    <FontAwesomeIcon icon={faGithub} />&nbsp;&nbsp;
                    <a target="_blank" rel="nofollow noreferrer" href="https://github.com/jlr2k8">https://github.com/jlr2k8</a>
                </p>
            </div>
        </div>
    );
}

export default AboutMe;