import {useEffect, useState} from "react";

function Footer() {

    const [gaseous, setGaseous] = useState([]);

/*    useEffect(() => {
        fetch('http://gaseous-dev-web/?json=true',{mode:'cors'})
            .then((response) => response.json())
            .then((data) => {
                setGaseous(data);
            }).catch(err => console.error(err));
    }, []);

    console.log(gaseous);*/

    return (
        <footer>
            <div id="footerOverlay">
                <p>
                    Copyright &copy; {new Date().getFullYear()} Josh L. Rogers. All rights reserved.
                </p>
            </div>
        </footer>
    );
}

export default Footer;