import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDocker, faGithub, faAws} from '@fortawesome/free-brands-svg-icons';
import {faBuildingUser} from '@fortawesome/free-solid-svg-icons';

function CurrentProjects() {
    return (
        <div className="scrollableSection" id="currentProjects">
            <div className="h1Container">
                <div className="h1ContainerOverlay">
                    <h1>
                        Current Projects
                    </h1>
                </div>
            </div>
            <div id="currentProjectsDetails">
                <h3><FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;Docker</h3>
                <p>
                    Docker images are continuously built and published through <a
                    href="https://github.com/jlr2k8/mydock">MyDock</a>. These scripts build the OS layer of Debian
                    (Bookworm), Apache 2.4, and PHP 8.x - each compiling images with the latest packages.
                </p>
                <p>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow" href="https://hub.docker.com/r/joshlrogers/node">Node
                                Latest</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow"
                                       href="https://github.com/jlr2k8/mydock/tree/main/projects/node">projects/node</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow" href="https://hub.docker.com/r/joshlrogers/apache">Apache
                                2.4</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow"
                                       href="https://github.com/jlr2k8/mydock/tree/main/projects/apache">projects/apache</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow" href="https://hub.docker.com/r/joshlrogers/php8-apache">Apache
                                with PHP 8</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow"
                                       href="https://github.com/jlr2k8/mydock/blob/main/projects/php8-apache">projects/php8-apache</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow" href="https://hub.docker.com/r/joshlrogers/debian">Debian
                                Bookworm</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow"
                                       href="https://github.com/jlr2k8/mydock/tree/main/projects/debian">projects/debian</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>
                <h3><FontAwesomeIcon icon={faAws}/>&nbsp;&nbsp;AWS Amplify</h3>
                <p>
                    <a target="_blank" rel="nofollow" href="https://www.nextgen.com/">
                        <img id="nextgen-logo" src="/nextgen.png" />
                    </a> At NextGen Healthcare, we are currently utilizing <a target="_blank" rel="nofollow"
                                                                              href="https://aws.amazon.com/amplify/">AWS
                    Amplify</a> for deploying internal services to streamline operations and improve
                    efficiency for hosting.
                </p>
                <h3><FontAwesomeIcon icon={faBuildingUser}/>&nbsp;&nbsp;Board of Directors (HOA)</h3>
                <p>
                    <img id="charts" src="/charts.png"/>
                    As treasurer for our HOA board, I serve our community by balancing our budget, working with vendors,
                    and doing maintenance throughout our property.
                </p>
            </div>
        </div>
    );
}

export default CurrentProjects;